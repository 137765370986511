<template>
  <a-form
    layout="vertical"
    :model="form"
    ref="formRef"
    name="add_user"
    id="add-user-form"
    :rules="validationsRules()"
    @finish="onSubmit"
  >
    <div class="row">
      <div class="d-none d-sm-flex col-sm-1 center">
        <div class="pre-icon os-icon os-icon-user-male-circle"></div>
      </div>
      <div class="col-sm-5">
        <a-form-item label="First Name" name="firstName">
          <a-input
            v-model:value="form.firstName"
            placeholder="Enter First Name"
            id="add-user-first-name-input"
          >
          </a-input>
        </a-form-item>
      </div>

      <div class="col-sm-6">
        <a-form-item label="Last Name" name="lastName">
          <a-input
            v-model:value="form.lastName"
            placeholder="Enter Last Name"
            id="add-user-last-name-input"
          >
          </a-input>
        </a-form-item>
      </div>
    </div>

    <div class="row">
      <div class="d-none d-sm-flex col-sm-1 center">
        <div class="pre-icon os-icon os-icon-user-male-circle"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item label="Role" name="role">
          <a-select
            class="w-100"
            v-model:value="form.role"
            placeholder="Select Role"
            :options="options"
            id="add-user-role-select-input"
          ></a-select>
        </a-form-item>
      </div>
    </div>

    <div v-if="availableRoles[form.role] == 'workstation_user'" class="row">
      <div class="row">
        <div class="d-none d-sm-flex col-sm-1 center">
          <div class="pre-icon os-icon os-icon-user"></div>
        </div>
        <div class="col-sm-11">
          <a-form-item label="Username" name="username">
            <a-input
              v-model:value="form.username"
              placeholder="Enter username"
              id="add-workstation-username"
            >
            </a-input>
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="d-none d-sm-flex col-sm-1 center">
          <div class="pre-icon os-icon os-icon-fingerprint"></div>
        </div>
        <div class="col-sm-5">
          <a-form-item label="Password" name="password" autocomplete="off">
            <a-input-password
              :visibilityToggle="true"
              v-model:value="form.password"
              placeholder="Password"
              @change="validPasswordPattern(form.password)"
            >
            </a-input-password>
            <div v-if="this.errors.length">
              <span style="color:red" v-for="error in errors" :key="error">
                {{ error }}
              </span>
            </div>
          </a-form-item>
        </div>

        <div class="col-sm-6">
          <a-form-item
            label="Confirm Password"
            name="confirmPassword"
            autocomplete="off"
          >
            <a-input-password
              :visibilityToggle="true"
              v-model:value="form.confirmPassword"
              placeholder="Confirm Password"
            />
          </a-form-item>
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div class="d-none d-sm-flex col-sm-1 center">
        <div class="pre-icon os-icon os-icon-email-2-at2"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item label="Email address" name="email" autocomplete="off">
          <a-input
            v-model:value="form.email"
            placeholder="Enter email"
            id="add-user-email-input"
          >
          </a-input>
        </a-form-item>
      </div>

    <!-- <div class="row">
      <div class="d-none d-sm-flex col-sm-1 center">
        <div class="pre-icon os-icon os-icon-user-male-circle"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item label="Role" name="role">
          <a-select
            class="w-100"
            v-model:value="form.role"
            placeholder="Select Role"
            :options="options"
            id="add-user-role-select-input"
          ></a-select>
        </a-form-item>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="d-none d-sm-flex col-sm-1" style="margin-top: 30px;">
        <div class="pre-icon os-icon os-icon-fingerprint"></div>
      </div>
      <div class="col-sm-5">
        <a-form-item label="Password" name="password" autocomplete="off">
          <a-input-password
            id="add-user-password-input"
            v-model:value="form.password"
            placeholder="Password"
            @change="validPasswordPattern(form.password)"
          />
           <div v-if="error"  style="color:red;font-size:13px;word-wrap:break-word;">
                {{ passwordErrorMessage }}
            </div>
          <div
            v-for="(error, index) of errors"
            :key="index"
            class="input-errors"
          >
            <div class="error-msg">{{ error }}</div>
          </div>
          <div class="validation-list">
            <a-checkbox
              v-for="(validation, index) in validations"
              :key="index"
              :checked="validation.check(form.password)"
              disabled
              @change="validation.check(form.password)"
              ><span
                :class="{
                  'is-checked': validation.check(form.password),
                }"
                >{{ validation.name }}</span
              >
            </a-checkbox>
          </div>
        </a-form-item>
        <div v-if="this.mobile_numberErrors.length">
          <span
            style="color:red"
            v-for="error in mobile_numberErrors"
            :key="error"
          >
            {{ error }}
          </span>
        </div>
      </div>

        <div class="col-sm-6">
          <a-form-item label="Confirm Phone Number" name="confirmmobile_number">
            <vue-tel-input
              v-model="form.confirmmobile_number"
              placeholder="Re-enter phone number"
              :validCharactersOnly="true"
              mode="international"
            ></vue-tel-input>
          </a-form-item>
        </div>
      </div>  -->
    </div>

    <a-space class="d-flex justify-content-center">
      <a-button
        type="primary"
        size="large"
        html-type="submit"
        :disabled="availableRoles[form.role] == 'workstation_user' ? validate(form.password) : false"
        :loading="isLoading"
        id="add-user-create-btn"
      >
        <span>Create</span>
      </a-button>

      <a-button
        @click="onCancel"
        size="large"
        id="add-user-cancel-btn"
      >
        <span>Cancel</span>
      </a-button>
    </a-space>
  </a-form>
</template>
<script>
import { roles, roleLabels } from 'src/config/roles-config';
import { ref } from 'vue';
import Spinner from '@/components/shared/Components/Spinner';
import { validUserName } from '../../../../utils/auth.js';

export default {
  props: ['roles', 'isLoading'],
  emits: ['addUser', 'cancel'],
  components: {
    SpinnerComponent: Spinner,
  },
  setup() {
    return { validUserName };
  },
  data() {
    return {
      // validations: [
      //   {
      //     name: 'Must be at least 8 characters long',
      //     check(password) {
      //       return password.length >= 8;
      //     },
      //   },
      //   {
      //     name: 'At least contain one uppercase letter',
      //     check(password) {
      //       return /[A-Z]/.test(password);
      //     },
      //   },
      //   {
      //     name: 'At least contain two numbers.',
      //     check(password) {
      //       return /\d{2}/.test(password);
      //     },
      //   },
      //   {
      //     name: 'At least contain two special characters.',
      //     check(password) {
      //       return /(?=(.*[^a-zA-Z0-9]){2})/.test(password);
      //     },
      //   },
      // ],
      options: [],
      passwordErrorMessage: "Choose stronger password with minimum 9 characters. Try a mix of a capital letter, 2 special characters and 2 numbers.",
      error: false,
      form: {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        position: '-',
        mobile_number: ref(null),
        confirmmobile_number: ref(null),
        role: '',
        // isValid: true,
      },

      errors: [],
      mobile_numberErrors: [],
      availableRoles: {}
    };
  },
  mounted() {
    this.options = this.roles
      .filter(
        (role) =>
          ![roles.admin, roles.labeler, roles.workstation_user].includes(
            role.name
          )
      )
      .map((el) => ({
        value: el.id,
        label: roleLabels[el.name],
      }));
    this.form.role = this.roles.find((r) => r.name === roles.org_user)?.id;

    this.roles
      .filter((role) => ![roles.admin, roles.labeler].includes(role.name))
      .map((el) => (this.availableRoles[el.id] = el.name));
  },

  methods: {
    async validPasswordPattern(password) {
      this.error = false;
      if (password.length < 9) {
        this.error = true
      }
      if (!/[A-Z]/.test(password)) {
        this.error = true
      }
      if (!/(?=(.*[^a-zA-Z0-9]){2})/.test(password)) {
        this.error = true
      }
      if (!/\d{2}/.test(password)) {
        this.error = true
      }
      return Promise.resolve();
    },
    validate(password) {
      if (password.length < 9) {
        return true;
      }
      if (!/[A-Z]/.test(password)) {
        return true;
      }
      if (!/(?=(.*[^a-zA-Z0-9]){2})/.test(password)) {
        return true;
      }
      if (!/\d{2}/.test(password)) {
        return true;
      }
      return false;
    },
    async validPassword(_, password) {
      if (password && password === this.form.password) {
        return Promise.resolve();
      } else {
        return Promise.reject('Confirm password must be equal to password');
      }
    },
    validationsRules() {
      return {
        firstName: [
          { required: true, validator: this.validUserName, trigger: 'submit' },
        ],
        lastName: [
          { required: true, validator: this.validUserName, trigger: 'submit' },
        ],
        username: [{ required: true, trigger: 'submit' }],
        email: [{ required: true, type: 'email', trigger: 'submit' }],
        password: [{ required: true, min: 6, trigger: 'submit' }],
        confirmPassword: [
          { required: true, validator: this.validPassword, trigger: 'submit' },
        ],
        // mobile_number: [
        //   {
        //     required: true,
        //     trigger: 'submit',
        //     message: 'Mobile number is required',
        //   },
        // ],
        // confirmmobile_number: [
        //   {
        //     required: true,
        //     validator: this.validmobile_number,
        //     trigger: 'submit',
        //   },
        // ],
      };
    },
    // async validmobile_number(_, mobile_number) {
    //   if (
    //     this.form.confirmmobile_number &&
    //     this.form.mobile_number === mobile_number
    //   ) {
    //     return Promise.resolve();
    //   } else {
    //     return Promise.reject('Phone Numbers must match');
    //   }
    // },
    selectedRole(roleId) {
      this.form.role = roleId;
    },
    adminCreatedUserPayload() {
      return {
        email: this.form.username,
        username: this.form.firstName,
        lastname: this.form.lastName,
        position: '-',
        is_active: true,
        role: this.form.role,
        org_admin_created_user: true,
        mobile_number: '+920000000000',
        confirmmobile_number: '+920000000000',
        password: this.form.password,
        confirm_password: this.form.confirmPassword,
        organization: localStorage.getItem('organization'),
      };
    },
    manualUserPayload() {
      return {
        email: this.form.email,
        username: this.form.firstName,
        lastname: this.form.lastName,
        position: '-',
        is_active: true,
        role: this.form.role,
        org_admin_created_user: true,
        mobile_number: '+920000000000',
        confirmmobile_number: '+920000000000',
        // mobile_number: this.form.mobile_number,
        // confirmmobile_number: this.form.confirmmobile_number,
      };
    },
    onSubmit() {
      let data = {};
      this.availableRoles[this.form.role] == 'workstation_user'
        ? (data = this.adminCreatedUserPayload())
        : (data = this.manualUserPayload());
      this.$emit('addUser', data);
      this.email = this.password = this.username = '';
    },
    onCancel() {
      this.$emit('cancel');
      this.$refs.formRef?.resetFields();
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  align-items: center;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
}
.is-checked {
  color: green;
}
</style>
